import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contents--multiple-choices"
export default class extends Controller {
  static targets = ["option"]
  static values = { correctAnswer: String }

  connect() {
    this.correctAnswer = this.data.get("correctAnswer")
  }

  checkAnswer(event) {
    const selectedAnswer = event.target.value
    this.clearStyles()
    this.optionTargets.forEach((option) => {
      const input = option.querySelector('input[type="radio"]')
      if (input.value == selectedAnswer) {
        if (selectedAnswer == this.correctAnswer) {
          option.classList.add('bg-green-100', 'dark:bg-green-600')
        } else {
          option.classList.add('bg-red-100', 'dark:bg-red-600')
        }
      }
    })
  }

  clearStyles() {
    this.optionTargets.forEach((option) => {
      option.classList.remove('bg-green-100', 'dark:bg-green-600', 'bg-red-100', 'dark:bg-red-600')
    })
  }
}
