import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-fields"
export default class extends Controller {
  static targets = ["hidden", "input"]

  connect() {
    this.inputTarget.addEventListener("change", this.toggle.bind(this))
    this.inputTarget.addEventListener("keyup", this.toggle.bind(this))
    this.toggle()
  }

  disconnect() {
    this.inputTarget.removeEventListener("change", this.toggle.bind(this))
    this.inputTarget.removeEventListener("keyup", this.toggle.bind(this))
  }

  toggle() {
    this.hiddenTargets.forEach((target) => {
      const targetValue = target.dataset.value ? target.dataset.value : null
      const greaterThanValue = target.dataset.greaterThan ? parseFloat(target.dataset.greaterThan) : null
      const lessThanValue = target.dataset.lessThan ? parseFloat(target.dataset.lessThan) : null

      let shouldShow = true

      if (targetValue !== null && this.value !== targetValue) { shouldShow = false }
      if (greaterThanValue !== null && this.value < greaterThanValue) { shouldShow = false }
      if (lessThanValue !== null && this.value > lessThanValue) { shouldShow = false }

      if (shouldShow) {
        target.classList.remove("hidden", "sm:hidden", "md:hidden", "lg:hidden")
      } else {
        target.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden")
      }
    })
  }

  get value() {
    switch (this.inputTarget.type) {
      case "checkbox":
        return this.inputTarget.checked ? "true" : "false"
      case "number":
        return this.inputTarget.value ? parseFloat(this.inputTarget.value) : null
      case "radio":
        let value = null
        this.inputTargets.forEach((input) => {
          if (input.checked) {
            input.parentElement.classList.add("bg-blue-100", "border-blue-500", "dark:bg-gray-800", "dark:border-gray-400")
            value = input.value
          } else {
            input.parentElement.classList.remove("bg-blue-100", "border-blue-500", "dark:bg-gray-800", "dark:border-gray-400")
          }
        })
        return value
      default:
        return this.inputTarget.value
    }
  }
}
