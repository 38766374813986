import { Controller } from "@hotwired/stimulus"
import distance from "natural/lib/natural/distance";
export default class extends Controller {
  connect() {
    this.replaceBlanksWithInputs();
    this.inputs.forEach((input, index) => {
      input.addEventListener("change", this.checkAnswer.bind(this));
    });
  }

  replaceBlanksWithInputs() {
    const contentElement = this.element;

    this.inputs.forEach((input, index) => {
      input.classList.add(
        "rounded-md",
        "border-0",
        "px-2",
        "py-1.5",
        "text-gray-900",
        "dark:text-gray-200",
        "dark:bg-gray-700",
        "shadow-sm",
        "ring-1",
        "ring-inset",
        "ring-gray-300",
        "placeholder:text-gray-400",
        "focus:ring-2",
        "focus:ring-inset",
        "focus:ring-indigo-600",
        "sm:max-w-xs",
        "sm:text-sm",
        "sm:leading-6"
      );
    });
  }

  checkAnswer(event) {
    const answer = event.target.value;
    const correctAnswer = event.target.dataset.answer;
    const similarity = distance.JaroWinklerDistance(answer, correctAnswer);
    if (similarity > 0.8) {
      event.target.classList.add("bg-green-100", "dark:bg-green-800");
      event.target.classList.remove("bg-red-100", "dark:bg-red-800");
    } else {
      event.target.classList.add("bg-red-100", "dark:bg-red-800");
      event.target.classList.remove("bg-green-100", "dark:bg-green-800");
    }
  }

  get inputs() {
    return this.element.querySelectorAll("input[type='text']");
  }
}
