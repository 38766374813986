export function initDarkMode() {
  const html = document.documentElement;

  function applyTheme(isDark) {
    html.classList.toggle('dark', isDark);
  }

  // Initial application of theme
  applyTheme(window.matchMedia('(prefers-color-scheme: dark)').matches);

  // Listen for changes in system preference
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    applyTheme(e.matches);
  });
}