// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./tailwindui.js";

import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import { initDarkMode } from "./dark_light_mode_toggle"

document.addEventListener("DOMContentLoaded", () => {
  initDarkMode();
});

document.addEventListener("turbo:load", () => {
  initDarkMode();
});
