import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["question"]

  checkAnswers() {
    if (this.questionTargets.length === 0) {
      return
    }

    this.questionTargets.forEach((question, index) => {
      const correctAnswer = question.dataset.answer.toLowerCase() === "true"
      const selectedAnswer = question.querySelector('input:checked')

      if (selectedAnswer) {
        const isCorrect = (selectedAnswer.value === "true") === correctAnswer
        this.showResult(question, isCorrect)
      } else {
        this.showResult(question, null)
      }
    })
  }

  showResult(question, isCorrect) {
    question.classList.remove("bg-green-100", "bg-red-100", "bg-yellow-100")

    if (isCorrect === true) {
      question.classList.add("bg-green-100")
      question.classList.add("dark:text-gray-900")
    } else if (isCorrect === false) {
      question.classList.add("bg-red-100")
      question.classList.add("dark:text-gray-900")
    } else {
      question.classList.add("bg-yellow-100")
      question.classList.add("dark:text-gray-900")
    }
  }
}