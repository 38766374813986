document.addEventListener("turbo:load", function () {
  const toggleBtns = document.querySelectorAll('[role="mobile-nav-toggle"]');
  const mobileNav = document.querySelector('[role="mobile-nav"]');
  if (!toggleBtns || !mobileNav) return;
  toggleBtns.forEach((toggleBtn) => {
    toggleBtn.addEventListener("click", function () {
      mobileNav.classList.toggle("hidden");
    });
  });
});
