import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="speech"
export default class extends Controller {
  static values = { text: String }

  isSpeaking = false
  utterance = new SpeechSynthesisUtterance(this.textValue)
  preferredVoices = [
    "Google US English",
    "Microsoft Aria Online (Natural) - English (United States)",
    "Microsoft Zira - English (United States)",
    "Samantha"
  ]

  connect() { }

  disconnect() {
    this.stopSpeaking()
  }

  speak() {
    if (!this.isSpeaking) {
      this.startSpeaking()
    } else {
      this.stopSpeaking()
    }
  }

  loadVoices() {
    return new Promise((resolve) => {
      const voices = speechSynthesis.getVoices()
      if (voices.length > 0) {
        this.setPreferredVoice(voices)
        resolve()
      } else {
        speechSynthesis.onvoiceschanged = () => {
          const voices = speechSynthesis.getVoices()
          this.setPreferredVoice(voices)
          resolve()
        }
      }
    })
  }

  setPreferredVoice(voices) {
    const preferredVoice = this.preferredVoices
      .map(name => voices.find(v => v.name === name))
      .find(Boolean);

    if (preferredVoice) {
      Object.assign(this.utterance, {
        voice: preferredVoice,
        lang: preferredVoice.lang
      });
    }
  }

  async startSpeaking() {
    await this.loadVoices()
    this.utterance.onend = () => this.speakingEnded()
    speechSynthesis.speak(this.utterance)
    this.isSpeaking = true
  }

  stopSpeaking() {
    speechSynthesis.cancel()
    this.isSpeaking = false
  }

  speakingEnded() {
    this.isSpeaking = false
  }
}
