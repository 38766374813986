import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cardContainer", "cardFront", "cardBack", "prevButton", "nextButton", "counter", "currentFlashCard"]
  static values = { cards: Array }

  connect() {
    this.currentIndex = 0
    this.isShowingQuestion = true
    this.updateCard()
    this.updateCounter()
  }

  previousCard() {
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.isShowingQuestion = true
      this.currentFlashCardTarget.classList.remove('flipped')
      this.updateCard()
      this.updateCounter()
    }
  }

  nextCard() {
    console.log("Next card")
    if (this.currentIndex < this.cardsValue.length - 1) {
      this.currentIndex++
      this.isShowingQuestion = true
      this.currentFlashCardTarget.classList.remove('flipped')
      this.updateCard()
      this.updateCounter()
    }
  }

  flipCard() {
    this.element.querySelector('.flash-card').classList.toggle('flipped');
  }

  shuffleCards() {
    const shuffled = [...this.cardsValue].sort(() => Math.random() - 0.5)
    this.cardsValue = shuffled
    this.currentIndex = 0
    this.showQuestion()
    this.updateCard()
    this.updateCounter()
  }

  showQuestion() {
    if (!this.isShowingQuestion) {
      this.cardContainerTarget.classList.remove('flipped')
      this.isShowingQuestion = true
    }
  }

  updateCard() {
    const card = this.cardsValue[this.currentIndex]
    if (!card) {
      console.error("Card not found at index", this.currentIndex)
      return
    }

    this.cardFrontTarget.textContent = card.question
    this.cardBackTarget.textContent = card.answer

    this.prevButtonTarget.disabled = this.currentIndex === 0
    this.nextButtonTarget.disabled = this.currentIndex === this.cardsValue.length - 1
  }

  updateCounter() {
    this.counterTargets.forEach(counter =>
      counter.textContent = `${this.currentIndex + 1}/${this.cardsValue.length}`
    )
  }
}