import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-more"
export default class extends Controller {
  static targets = ["collapsedContent", "fullContent", "toggleLink"]
  static values = {
    show: String,
    hide: String
  }

  expanded = false

  connect() {

    this.fullContentTarget.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden")
  }

  toggle(event) {
    event.preventDefault()
    this.expanded = !this.expanded
    this.updateView()
  }

  updateView() {
    if (this.expanded) {
      this.collapsedContentTarget.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden")
      this.fullContentTarget.classList.remove("hidden", "sm:hidden", "md:hidden", "lg:hidden")
      this.toggleLinkTarget.textContent = this.hideValue
    } else {
      this.collapsedContentTarget.classList.remove("hidden", "sm:hidden", "md:hidden", "lg:hidden")
      this.fullContentTarget.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden")
      this.toggleLinkTarget.textContent = this.showValue
    }
  }
}
